import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Hi, I'm Arno.</h1>
        <p>Welcome to my website.</p>          
      </header>
    </div>
  );
}

export default App;
